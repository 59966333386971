import React from 'react'
import Layout from '../components/Layout'
// import Helmet from 'react-helmet'
// import Hero from '../components/Hero'
import Container from '../components/container'
import clubGIF from '../images/clubs.gif'
import { aboutus, clubs1,clubs2 } from './about.module.css'

function About(props) {
    console.log(props.location.path)
    return (
        <Layout location={props.location} >
            {/* <Helmet title={siteTitle} /> */}
            <div className={aboutus}>
                <h1 className="pageTitle">About</h1>
                <Container>
                    <p>
                    Crowlin Circus is a professional entertainment company that has been working in Europe for several years, offering a wide range of performances that include stilt walking, magic shows, monocycle, tight and slack rope, fakir/suspension, and fire shows featuring juggling, staff, poi, cube, Catherine wheel, ropes, fire cannon, body burning, and fire spitting. We have two fire shows available for hire, with one lasting 15 minutes and the other lasting 25 minutes.
Our shows are suitable for any event or venue, including parties, weddings, corporate events, festivals, concerts, launches, openings, and promotional advertising. In addition to our performances, we also have three large tents (12m x 6m) available for hire.
                    </p>

                    <img src={clubGIF} alt="" className={clubs2} />
                    <img src={clubGIF} alt="" className={clubs1} />

                    <p>Crowlin Circus has a wealth of experience performing at a variety of events and locations, including bars, clubs, weddings, fairs, and films such as Braveheart, Rob Roy, Hamish McBeth, and Paradise Blues. We have also performed in conjunction with bands and DJs such as DJ Afar (The Kronik People) and Blasted Mechanism. We have a range of audio and video equipment available for use as well. Our professional and highly skilled performers have garnered a reputation for excellence, making us a top choice for any event.
                    </p>

                    <p>
                        Crowlin Circus é uma empresa de entretenimento profissional que atua na Europa há vários anos, oferecendo uma ampla variedade de apresentações, incluindo palhaços, mágicas, monociclo, corda tensa e slack, fakir / suspensão e espetáculos de fogo com malabarismos, varas, poi, cubo, roda de Catherine, cordas, canhão de fogo, queimadura corporal e cuspe de fogo. Temos dois espetáculos de fogo disponíveis para contratação, sendo um de 15 minutos e o outro de 25 minutos.
                    </p>
                    <p>
                    Nossos espetáculos são adequados para qualquer evento ou local, incluindo festas, casamentos, eventos corporativos, festivais, concertos, lançamentos, inaugurações e publicidade promocional. Além de nossas apresentações, também temos três grandes barracas (12m x 6m) disponíveis para contratação.
A Crowlin Circus tem muita experiência em apresentações em uma variedade de eventos e locais, incluindo bares, clubes, casamentos, feiras e filmes como Braveheart, Rob Roy, Hamish McBeth e Paradise Blues. Também já apresentamos em conjunto com bandas e DJs, como DJ Afar (The Kronik People) e Blasted Mechanism. Temos uma série de equipamentos de áudio e vídeo disponíveis para uso também. Nossos artistas profissionais e altamente qualificados têm uma reputação de excelência, o que nos torna uma ótima opção para qualquer evento.
                    </p>

                </Container>
            </div>
        </Layout>

    )
}

export default About